<template>
  <div>
    <h3>DETAIL AGENDA BAPPEDA</h3>
  </div>
</template>



<script>
export default {};
</script>